import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./StorageUserImageComponent.scss";
import { uploadFile } from "../../databases/storage";
import AlertModal from "../AlertModal/AlertModal";

const StorageUserImageComponent = (props: {uid: string;}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const userId = props.uid;

  const fileInputRef: any = useRef(null);

  const handleUpload = async () => {
    if (selectedFile && userId && !uploading) {
      setUploading(true);
      const allowedImageTypes = ['image/jpeg', 'image/png'];
      const maxFileSize = 200 * 1024; // 200 KB

      if (allowedImageTypes.includes(selectedFile.type) && selectedFile.size <= maxFileSize) {
        const fileName = `students-photos/${userId}.jpg`; // Use .jpg for both JPEG and PNG
        try {
          await uploadFile(selectedFile, fileName);
          setSuccessMessage("Imagem de perfil enviada com sucesso!");
          setShowModal(true);

          // Esperar por 3 segundos antes de reiniciar o componente
          setTimeout(() => {
            setUploading(false);
            setSelectedFile(null);
          }, 3000);

          navigate(-1);

        } catch (error) {
          console.error("Erro ao enviar a imagem de perfil:", error);
          setShowModal(true);
          setError(`Erro ao enviar a imagem de perfil: ${error}`);
          setUploading(false);
        }
      } else {
        setError("Selecione uma imagem JPEG ou PNG com tamanho máximo de 200 KB.");
        setShowModal(true);
        setUploading(false);
      }
    } else {
      console.log("Preencha todos os campos ou aguarde o envio anterior ser concluído.");
      setError("Selecione uma imagem JPEG ou PNG com tamanho máximo de 200 KB.");
      setShowModal(true);
    }
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }
  };

  useEffect(() => {
    // Limpar o input de arquivo após o envio
    fileInputRef.current.value = null;
  }, [uploading]);

  const handleCloseModal = () => {
    setShowModal(false);
    setError('');
    setSuccessMessage('');
  };

  return (
    <div>
      {userId && (
        <>
          <div className="container">
            <h1>Envio de Imagem de Perfil</h1>
            <label className="w-100 text-start">Selecione a imagem de perfil:</label>
            <input
              type="file"
              className="input-group mb-3"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <button onClick={handleUpload} className="button_storage">
              {uploading ? "Enviando..." : "Enviar Imagem de Perfil"}
            </button>
          </div>

          {showModal && successMessage && (
            <AlertModal message={successMessage} onClose={handleCloseModal} />
          )}

          {showModal && error && (
            <AlertModal message={error} onClose={handleCloseModal} />
          )}
        </>
      )}
    </div>
  );
};

export default StorageUserImageComponent;