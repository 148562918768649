import './HomeAdm.scss';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa6";
import { MdCreateNewFolder } from "react-icons/md";
import { PiStudentFill } from "react-icons/pi";
import { GrScheduleNew } from "react-icons/gr";
import { BsListColumnsReverse } from "react-icons/bs";
import CardIcons from '../../../components/CardIcons/CardIcons';
import { auth } from '../../../databases/firebaseConfig';
import { getFileURL } from '../../../databases/storage';
import { UserData } from '../../../interfaces/Students';
import { TurmaOptionData } from '../../../interfaces/Turmas';
import { signOut } from '../../../utils/SingOut';
import StorageComponent from '../../../components/StorageComponent/StorageComponent';
import HeaderComponent from '../../../components/HeaderComponent/HeaderComponent';
import CardIconsNoNavigation from '../../../components/CardIconsNoNavigation/CardIconsNoNavigation';
import { VscDebugStepBack } from "react-icons/vsc";

const HomePageAdm: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const uid = state?.uid || localStorage.getItem('code');
  const email = state?.email;
  const role = state?.role ? state?.role : "student";
  const logoUrl = state?.logoUrl;
  const name = state?.userName;

  const [imgUrl, setImgUrl] = useState('');

  const userFirstData: UserData = {
    uid: uid,
    id: uid,
    isFirstAccess: false,
    email: email,
    role: role,
    name: name
  };

  const [opcoesTurmaData, setOpcoesTurmaData] = useState<TurmaOptionData[]>([]);
  const [storageOpen, setStorageOpen] = useState(false);

  // Check if the user is already signed in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (role && role === "student") {
      handleLogout();
    }
  }, []);

  useEffect(() => {
    // Carrega a imagem de fundo ao montar o componente
    const loadBackgroundImage = async () => {
      try {
        const imageUrl = await getFileURL("adms-page/background");
        // Aplique a URL no CSS
        document.documentElement.style.setProperty('--background-image-adm', 'url(' + imageUrl + ')');
        
        const imageUrlMobile = await getFileURL("adms-page/background-mobile");
        document.documentElement.style.setProperty('--background-image-mobile-adm', 'url(' + imageUrlMobile + ')');

        setImgUrl(imageUrl);

      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Carrega a imagem de fundo ao montar o componente
    loadBackgroundImage();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut();
      // Redirecione para a página de login após o logout
      //navigate("/");
    } catch (error: any) {
      console.error("Erro ao fazer logout:", error.message);
    }
  };

  // useEffect(() => {
  //   const cursosData = async () => {
  //     try {
  //       let cursos = await getCoursesFirestore();
  //       setOpcoesTurmaData(cursos);
  //       localStorage.setItem("courses", JSON.stringify(cursos));
  //     } catch (error) {
  //       console.error(
  //         "Erro ao carregar os cursos cadastrados pelo professor:",
  //         error
  //       );
  //     }
  //   };

  //   cursosData();
  // }, []);

  return (
    <div className="adms-page">
      <HeaderComponent urlPhoto={uid} isFirstPage={true} nome={`Bem-vindo ${role}`} logoUrl={logoUrl} />

      {(role === "adm" && !storageOpen) && (
      <div className="adms-page-container">
            <CardIcons
              text="RELATÓRIOS DOS ALUNOS"
              url="/app/adms/logged/reports/students"
              color="green"
              user={userFirstData}
              imgUrl={imgUrl}
              iconCode={BsListColumnsReverse}
              role={role}
              curso={opcoesTurmaData}
              logoUrl={logoUrl}
              />

            <CardIcons
              text="CADASTRAR AULAS"
              url="/app/adms/logged/adm/new/classes"
              color="purple"
              user={userFirstData}
              imgUrl={imgUrl}
              iconCode={MdCreateNewFolder}
              role={role}
              curso={opcoesTurmaData}
              logoUrl={logoUrl}
            />

            <CardIcons
              text="CADASTRAR SIMULADOS"
              url="/app/adms/logged/adm/new/simulados"
              color="firebrick"
              imgUrl={imgUrl}
              user={userFirstData}
              iconCode={GrScheduleNew}
              role={role}
              curso={opcoesTurmaData}
              logoUrl={logoUrl}
            />

            <CardIcons
              text="CADASTRAR USUÁRIOS"
              url="/app/adms/logged/adm/new/users"
              color="brown"
              user={userFirstData}
              imgUrl={imgUrl}
              iconCode={FaUserPlus}
              role={role}
              curso={opcoesTurmaData}
              logoUrl={logoUrl}
            />
            

            <CardIcons
              text="MODO ESTUDANTE"
              url="/app/students/logged"
              color="yellowgreen"
              user={userFirstData}
              imgUrl={imgUrl}
              iconCode={PiStudentFill}
              role={role}
              curso={opcoesTurmaData}
              logoUrl={logoUrl}
            />

            <div onClick={() => setStorageOpen(true)}>
              <CardIconsNoNavigation
                text="PERSONALIZAR PLATAFORMA"
                color="magenta"
                iconCode={GrScheduleNew}
              />
            </div>
      </div>
        )}
      
      {storageOpen && (
        <>
        <div onClick={() => setStorageOpen(false)} style={{marginBottom: '20px'}}>
              <CardIconsNoNavigation
                text="VOLTAR"
                color="gray"
                iconCode={VscDebugStepBack}
              />
            </div>
        <StorageComponent />
        </>
      )}
    </div>
  );
};

export default HomePageAdm;

