import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Certifique-se de ter o react-router-dom instalado se estiver usando roteamento
import { IconContext } from "react-icons";
import { BiError } from "react-icons/bi"; // Substitua BiError pelo ícone padrão que você deseja usar quando o código do ícone não for fornecido
import { CardIconProps } from "../../interfaces/PropsModels";


const CardIcons = (props: CardIconProps) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  
  const handleCardClick = () => {
    if (props.url && props.url.startsWith("http")) {
      //window.location.href = props.url;
      window.open(props.url, '_blank');
    } else if (props.url === '-1') {
      // volta a página que estava antes
      navigate(-1);
    } else {
      // Use a função de navegação aqui (substitua navigate pela função real que você está usando para navegação)
      navigate(props.url, { state: { role: props.role, user: props.user, imgUrl: props.imgUrl, cursoData: props.curso, logoUrl: props.logoUrl } });
    }
  };

  return (
    <IconContext.Provider value={{ color: "white", size: "2em" }}>
      <div
        className="dynamic-card"
        style={{
          backgroundColor: `${props.color}`,
          width: '140px',
          height: '140px',
          cursor: 'pointer',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: '4px',
          fontSize: '2rem',
          transition: 'background-color 0.3s', // Adiciona uma transição suave para a mudança de cor
          boxShadow: isHovered ? '0 0 10px rgba(0, 0, 0, 0.3)' : 'none', // Adiciona uma sombra ao efeito hover
        }}
        onClick={handleCardClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {props.iconCode ? React.createElement(props.iconCode) : <BiError />}
        <p
        style={{
            fontSize: '1rem',
            margin: 0,
            fontWeight: 'bold',
            color: '#fff',
            textAlign: 'center'
          }}
        >{props.text}</p>
      </div>
    </IconContext.Provider>
  );
};

export default CardIcons;
