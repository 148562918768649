import React, { useEffect, useState } from 'react';
import './HomePage.scss'
import { Link } from 'react-router-dom';
import { getFileURL } from '../../databases/storage';

const HomePage: React.FC = () => {
  const [logo, setLogo] = useState("");

  useEffect(() => {
    // Carrega a imagem de fundo ao montar o componente
    const loadBackgroundImage = async () => {
      try {
        const imageUrl = await getFileURL("home-page/background");
        // Aplique a URL no CSS
        document.documentElement.style.setProperty('--background-image', 'url(' + imageUrl + ')');
        
        const imageUrlMobile = await getFileURL("home-page/background-mobile");
        document.documentElement.style.setProperty('--background-image-mobile', 'url(' + imageUrlMobile + ')');


        const imageLogoUrl = await getFileURL("login-page/logotipo");

        setLogo(imageLogoUrl);

      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Carrega a imagem de fundo ao montar o componente
    loadBackgroundImage();
  }, []);
  
  return (
    <div className='home-page'>
      {logo && (
        <img src={logo} alt="logo matematica do zero" className='signup-logotipo'/>
      )}
      <h1 style={{textAlign: 'center'}}>PLATAFORMA DO MATEMÁTICA DO ZERO</h1>
      <p>Seja Bem-vindo!</p>
      <Link to={'/login'} style={{backgroundColor: '#0000E7', padding: '4px 8px', borderRadius: '4px', color: '#fff', textDecoration: 'none'}}> FAZER LOGIN </Link>
    </div>
  );
};

export default HomePage;
