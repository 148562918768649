import { auth } from '../databases/firebaseConfig';

export const signOut = async () => {
  try {
    await auth.signOut();
    //console.log('Usuário saiu com sucesso!');
  } catch (error: any) {
    console.error('Erro ao sair: ', error.message);
  }
};
