import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./StorageComponent.scss";
import { uploadFile } from "../../databases/storage";
import AlertModal from "../AlertModal/AlertModal";

const StorageComponent = () => {
  const [folder, setFolder] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { state } = useLocation();
  const role = state?.role;

  const fileInputRef: any = useRef(null);

  const handleFolderChange = (e: any) => setFolder(e.target.value);

  const handleFileNameChange = (e: any) => setSelectedFileName(e.target.value);

  const handleUpload = async () => {
    if (selectedFile && selectedFileName && folder && !uploading) {
      setUploading(true);
      const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

      if (allowedImageTypes.includes(selectedFile.type)) {
        const path = `${folder}/${selectedFileName}`;
        try {
          await uploadFile(selectedFile, path);
          setSuccessMessage("Arquivo enviado com sucesso!");
          setShowModal(true);
        

          // Esperar por 3 segundos antes de reiniciar o componente
          setTimeout(() => {
            setUploading(false);
            setFolder("");
            setSelectedFile(null);
            setSelectedFileName("");
          }, 3000);
        } catch (error) {
          console.error("Erro ao enviar o arquivo:", error);
          setShowModal(true);
          setError(`Erro ao enviar o arquivo: ${error}`);
          setUploading(false);
        }
      } else {
        setError("Selecione um arquivo de imagem válido.\nJPEG - PNG - GIF - WEBP");
        setShowModal(true);
        setUploading(false);
      }
    } else {
      console.log("Preencha todos os campos ou aguarde o envio anterior ser concluído.");
      setError("Selecione um arquivo de imagem válido.\nJPEG - PNG - GIF - WEBP");
      setShowModal(true);
    }
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

      if (allowedImageTypes.includes(file.type)) {
        setSelectedFile(file);
      } else {
        setError("Selecione um arquivo de imagem válido.\nJPEG - PNG - GIF - WEBP");
        setShowModal(true);
        fileInputRef.current.value = null;
      }
    } else {
      setSelectedFile(null);
    }
  };

  useEffect(() => {
    // Limpar o input de arquivo após o envio
    fileInputRef.current.value = null;
  }, [uploading]);

  const handleCloseModal = () => {
    setShowModal(false);
    setError('');
    setSuccessMessage('');
  };

  return (
    <div>
      {role !== "student" && (
        <>
          <div className="container">
            <h1>Envio de Arquivos</h1>
            <div className="form-group">
            <label className="w-100 text-start">Nome da pasta de arquivos:</label>
            <select value={folder} onChange={handleFolderChange} className="form-control mb-3">
              <option value="" disabled>
                Selecione a Pasta
              </option>
              <option value="login-page">Login Page</option>
              <option value="home-page">Home Page</option>
              <option value="students-page">Students Page</option>
              <option value="adms-page">ADMs Page</option>
              <option value="payment-page">Payment Page</option>
            </select>
            </div>

            <div className="form-group">
            <label className="w-100 text-start">Nome do Arquivo:</label>
            <select value={selectedFileName} onChange={handleFileNameChange} className="form-control mb-3">
              <option value="" disabled>
                Selecione o tipo de imagem que vai inserir
              </option>
              <option value="logotipo">Logotipo</option>
              <option value="background">Background</option>
              <option value="background-mobile">Background Mobile</option>
              <option value="icone">Ícone</option>
            </select>
            </div>

            <input
              type="file"
              className="input-group mb-3"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <button onClick={handleUpload} className="button_storage">
              {uploading ? "Enviando..." : "Enviar Arquivo"}
            </button>
          </div>

          {showModal && successMessage && (
          <AlertModal message={successMessage} onClose={handleCloseModal} />
        )}

        {showModal && error && (
          <AlertModal message={error} onClose={handleCloseModal} />
        )}
        </>
      )}
    </div>
  );
};

export default StorageComponent;
