
import { isPast, format, Locale } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Link, useParams } from 'react-router-dom'
import classNames from 'classnames';
import { CheckCircle, Lock } from 'phosphor-react'
import './Lesson.scss';


interface LessonProps {
    title: string;
    slug: string;
    availableAt: Date;
    type: string;
    logoUrl: string;
    studentName: string;
}

export function Lesson(props: LessonProps){
    const { slug } = useParams<{ slug: string }>()

    const isLessonAvailable = isPast(props.availableAt);
    const availableDateFormatted = format(props.availableAt, "EEEE' • 'd' de 'MMMM' • 'k'h'mm", {
        locale: ptBR as Locale,
    })

    const isActiveLesson = slug === props.slug

    return(
        <Link to={isLessonAvailable ? `/app/students/logged/lesson/${props.slug}` : '#'} className="lesson" state={{logoUrl: props.logoUrl, userName: props.studentName}}>
            <span style={{color: '#8D8D99'}}>
                {availableDateFormatted}
            </span>

            <div 
                className={classNames('lesson-card',{'lesson-card-blue': isActiveLesson,})}
            >
                <header className="lesson-card_header">
                    {
                        isLessonAvailable ? (
                            <span 
                                className={classNames('lesson-card_header-available', {
                                    'text-white': isActiveLesson,
                                    'text-blue-500': !isActiveLesson
                                })}
                            >
                                <CheckCircle size={20}/>
                                Conteúdo liberado
                            </span>
                        ) : (
                            <span className="lesson-card_header-unavailable">
                                <Lock size={20}/>
                                Em breve
                            </span>
                        )
                    }
                    <span 
                        className={classNames('lesson-card_type', {
                            'border-white': isActiveLesson,
                            'border-blue-700': !isActiveLesson
                        })}
                    >
                        {props.type === 'live' ? 'AO VIVO' : 'AULA GRAVADA'}
                    </span>
                </header>
                <strong 
                className={classNames("lesson-card_title", {
                    'text-white': isActiveLesson,
                    'text-gray-200': !isActiveLesson
                })}
                >
                    {props.title}
                </strong>
            </div>
        </Link>
    )
}