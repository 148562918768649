import React, { useEffect, useState } from "react";
import "./CreateUsers.scss";
import { useLocation, useNavigate } from "react-router-dom";
import loading from "../../../assets/images/background-default.gif";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { auth } from "../../../databases/firebaseConfig";
import { signOut } from "../../../utils/SingOut";
import { signUpWithValidate } from "../../../utils/SingUp";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import AlertModal from "../../../components/AlertModal/AlertModal";
import { UserData } from "../../../interfaces/Students";
import { getUsersFirestore, removeUserFirestore } from "../../../databases/firestore";

const CreateUsers: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const role = state?.role;
  const uid = state?.uid || localStorage.getItem('code');
  const imgUrl = state?.imgUrl ? state?.imgUrl : loading;
  const logoUrl = state?.logoUrl;

  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cargo, setCargo] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [usuarios, setUsuarios] = useState<UserData[]>([]);

  // Função para carregar os cursos ao montar o componente
  useEffect(() => {
    //Carrega apenas uma vez a chamada;
    loadStudents();
  }, []);

  const loadStudents = async () => {
    try {
      const usersData = await getUsersFirestore();
      setUsuarios(usersData);
    } catch (error) {
      console.error("Erro ao carregar usuários:", error);
    }
  };

  // Check if the user is already signed in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    if (role === "student") {
      handleLogout();
    }
    // Carrega a imagem de fundo ao montar o componente
    document.body.style.backgroundImage = `url(${imgUrl})`;

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
    
  }, []);

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error: any) {
      console.error("Erro ao fazer logout:", error.message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError('');
    setSuccessMessage('');
  };

  function phoneMask(valor: string) {
    return valor
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/g, "($1) $2")
      .replace(/(\d)(\d{4})$/, "$1-$2");
  }

  const handleSignUp = async () => {
    try {
      await signUpWithValidate(email, password, cargo, true, name, telefone);
      // SignUp successful, update the state with success message
      setSuccessMessage("Cadastro realizado com sucesso!");
      setShowModal(true);

      loadStudents(); // Atualiza a lista de usuarios após a remoção

      // Optionally, clear the email and password fields after successful signup
      setEmail("");
      setPassword("");
      setName("");
      setTelefone("");
      setCargo("");
    } catch (error: any) {
      setError(error.message);
      setShowModal(true);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isEmailValid = (email: string) => {
    // Use a regular expression to check if the email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRemoveUser = async (simuladoId: string) => {
    try {
      await removeUserFirestore(simuladoId);
      setSuccessMessage("Simulado removido com sucesso!");
      setShowModal(true);
      loadStudents(); // Atualiza a lista de usuarios após a remoção
    } catch (error: any) {
      setError(error.message);
      setShowModal(true);
    }
  };

  return (
    <div className="adms-page-create">
      <HeaderComponent urlPhoto={uid} isFirstPage={false} nome={`Bem-vindo ${role}`} logoUrl={logoUrl}/>

      <h4 style={{color: '#fff', marginBottom: '20px'}}>CADASTRO DE USUÁRIOS</h4>
      <div className="card-container-users">
        <form className="form-signup new-users was-validated" autoComplete="off">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              title="Insira nome e sobrenome do colaborador!"
              autoComplete="off"
            />
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Telefone"
              value={telefone}
              onChange={(e) => setTelefone(phoneMask(e.target.value))}
              required
              title="Insira o telefone do colaborador!"
              autoComplete="off"
              minLength={14}
              maxLength={15}
            />
          </div>
          <select
            value={cargo}
            onChange={(e) => setCargo(e.target.value)}
            className="form-control mb-3"
            required
          >
            <option value="" hidden disabled>
              Escolha o tipo de usuário
            </option>
            <option value="secretaria">Secretária(o)</option>
            <option value="corretor">Corretor(a)</option>
            <option value="adm">Administrador(a)</option>
            <option value="professor">Professor(a)</option>
            <option value="student">Aluno(a)</option>
          </select>
          <div className="input-group mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
              title="Insira um email válido!"
              autoComplete="off"
            />
          </div>

          <div className="input-group mb-3">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="off"
            />
            <div className="input-group-append">
              <span
                className="input-group-text  eye-button"
                onClick={toggleShowPassword}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <div className="buttons-container">
            <button
              className="btn btn-primary adm-button"
              type="button"
              onClick={handleSignUp}
              disabled={!isEmailValid(email) || password === ""}
            >
              CONCLUIR CADASTRO
            </button>
          </div>
        </form>

        <div className="create-simulados-list" style={{ color: "#fff" }}>
          {usuarios.length > 0 && (
            <>
              <h3 style={{ color: "#fff", marginTop: "20px", textAlign: 'center' }}>
                Lista de Usuários Cadastrados
              </h3>
              <ul>
                {usuarios.map((user) => (
                  <li key={user.id}>
                    <span>{user.name}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveUser(user.id)}
                      className="btn btn-warning"
                    >
                      Remover
                    </button>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>

        {showModal && successMessage && (
          <AlertModal message={successMessage} onClose={handleCloseModal} />
        )}

        {showModal && error && (
          <AlertModal message={error} onClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
};

export default CreateUsers;

