import React, { useEffect, useState } from "react";
import { BiLogOutCircle } from "react-icons/bi";
import "./HeaderComponent.scss";
import user from "../../assets/images/userDefault.png";
import { UserAdmHeaderProps } from "../../interfaces/Students";
import { signOut } from "../../utils/SingOut";
import CardIconsHeader from "../CardIconsHeader/CardIconsHeader";
import { useNavigate } from "react-router-dom";
import { getFileURL } from "../../databases/storage";
import { SiGoogleclassroom } from "react-icons/si";

const HeaderComponent = (props: UserAdmHeaderProps) => {
  const navigate = useNavigate();
  const [userPhoto, setUserPhoto] = useState(user);

  useEffect(() => {
    // Carrega a imagem de fundo ao montar o componente
    const loadPerfilImg = async () => {
      try {
        const imageUrl = await getFileURL(`students-photos/${props.urlPhoto ? props.urlPhoto : localStorage.getItem('code')}.jpg`);

        if (imageUrl) {
          setUserPhoto(imageUrl);
        } else {
          setUserPhoto(user);
        }


      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Carrega a imagem de fundo ao montar o componente
    loadPerfilImg();
  }, []);

  const nome = props.nome ? props.nome : "Nome do Usuário";

  // Dividindo a string em palavras
  const partesDoNome = nome.split(" ");

  // Obtendo o primeiro e o último nome
  const primeiroNome = partesDoNome[0];
  const ultimoNome = partesDoNome[partesDoNome.length - 1];

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error: any) {
      console.error("Erro ao fazer logout:", error.message);
    }
  };

  const changePhoto = () => {
    navigate("/app/students/logged/change/photos", { state: { uid: props.urlPhoto || userPhoto, userName: nome, logoUrl: props.logoUrl } });
  }

  return (
    <>
    
    <header className="adm-header">
      <div className="adm-header-container">
        <div className="header-img-container-brand">
          <img
            src={props.logoUrl}
            alt="Imagem da marca"
            className="header-img-brand_mobile"
          />
          <div className="header-img-container" onClick={changePhoto}>
            <img
              src={userPhoto}
              alt="Imagem do usuário"
              style={{ width: "120px", height: "120px", marginBottom: "20px", cursor: "pointer" }}
              className="header-img"
            />
            <p className="header-name">{`${primeiroNome} ${ultimoNome}`}</p>
          </div>
        </div>
        
        <div className="header-img-container-brand">
          <img
            src={props.logoUrl}
            alt="Imagem da marca"
            className='header-img-brand'
          />
        </div>
        {props.isFirstPage === 'homeStudent' && 
          <div>
            <CardIconsHeader
              text="PAINEL"
              url="/app/students/logged"
              color="#fff"
              iconCode={SiGoogleclassroom}
              role="student"
              logoUrl={props.logoUrl}
              imgUrl={props.urlPhoto}
            />
          </div>
        }

        {(!props.isFirstPage || (props.isFirstPage === 'homeStudent')) && 
          <div>
            <CardIconsHeader
              text="VOLTAR"
              url="-1"
              color="#fff"
              iconCode={BiLogOutCircle}
              role="adm"
              logoUrl={props.logoUrl}
              imgUrl={props.urlPhoto}
            />
          </div>
        }

        {props.isFirstPage && !(props.isFirstPage === 'homeStudent') && 
          <div onClick={handleLogout}>
            <CardIconsHeader
              text="SAIR"
              url="/"
              color="#fff"
              iconCode={BiLogOutCircle}
              role="adm"
              logoUrl={props.logoUrl}
              imgUrl={props.urlPhoto}
            />
          </div>
        }
      </div>
    </header>

    <header className="adm-header-mobile">
      <div className="adm-header-mobile-container">
        <div className="header-img-container-brand-mobile">
          {props.isFirstPage === 'homeStudent' && 
            <div className="painel" style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '-80px', marginLeft: '-20px', zIndex: '2', alignSelf: 'flex-start' }}>
              <CardIconsHeader
                text="PAINEL"
                url="/app/students/logged"
                color="#fff"
                iconCode={SiGoogleclassroom}
                role="student"
                logoUrl={props.logoUrl}
                imgUrl={props.urlPhoto}
              />
            </div>
          }

          {(!props.isFirstPage || (props.isFirstPage === 'homeStudent')) && 
            <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginRight: '-30px'}}>
              <CardIconsHeader
                text="VOLTAR"
                url="-1"
                color="#fff"
                iconCode={BiLogOutCircle}
                role="adm"
                logoUrl={props.logoUrl}
                imgUrl={props.urlPhoto}
              />
            </div>
          }

          {props.isFirstPage && !(props.isFirstPage === 'homeStudent') && 
            <div onClick={handleLogout} style={{width: '100%', display: 'flex', justifyContent: 'flex-end', marginRight: '-30px'}}>
              <CardIconsHeader
                text="SAIR"
                url="/"
                color="#fff"
                iconCode={BiLogOutCircle}
                role="adm"
                logoUrl={props.logoUrl}
                imgUrl={props.urlPhoto}
              />
            </div>
          }

          <img
            src={props.logoUrl}
            alt="Imagem da marca"
            className="header-img-brand_mobile"
          />
        </div>
        <div className="header-img-container">
          <p className="header-name">{`${primeiroNome} ${ultimoNome}`}</p>
        </div>
      </div>
    </header>
    </>
  );
};

export default HeaderComponent;
