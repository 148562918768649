import { auth, firestore as db } from "../databases/firebaseConfig";

export const signIn = async (email: string, password: string) => {
  try {
    // Realize o login no Firebase Authentication
    const userCredential = await auth.signInWithEmailAndPassword(
      email,
      password
    );

    // Acesse o UID do usuário autenticado
    const uid = userCredential.user?.uid;

    // Recupere os dados do usuário do Firestore ou do Realtime Database
    if (uid) {
      const userSnapshot = await db.collection("users").doc(uid).get();
      const userData = userSnapshot.data();

      // Verifique se há dados e se há um campo 'role'
      if (userData && userData.role) {
        const userRole = userData.role;
        //console.log('User signed in successfully! User Role:', userRole);
        //localStorage.setItem('role', userRole);
        return { userRole, uid, email };
      } else {
        console.error("Dados do usuário ou função não encontrados.");
      }
    } else {
      console.error("Falha ao obter o UID do usuário conectado.");
    }
  } catch (error: any) {
    console.error("Erro ao fazer login:", error.message);
    throw error; // Propaga o erro para que o chamador possa lidar com ele, se necessário
  }
};

export const forgotPassword = async (emailAddress: string): Promise<number> => {
  try {
    await auth.sendPasswordResetEmail(emailAddress);
    // E-mail de redefinição de senha enviado com sucesso
    console.log("E-mail enviado com sucesso");
    return 200;
  } catch (error: any) {
    // Ocorreu um erro ao enviar o e-mail de redefinição de senha
    console.error(error.message);
    return 404;
  }
};

export const signInWithValidate = async (email: string, password: string) => {
  try {
    // Faça o login no Firebase Authentication
    const userCredential = await auth.signInWithEmailAndPassword(
      email,
      password
    );

    // Acesse o usuário autenticado
    const user = userCredential.user;

    // Verifique se o e-mail foi verificado
    if (user && user.emailVerified) {
      //alert("Login bem-sucedido!");
      //console.log("Login bem-sucedido!");

      // Acesse o UID do usuário autenticado
      const uid = userCredential.user?.uid;

      if (uid) {
        const userSnapshot = await db.collection("users").doc(uid).get();
        const userData = userSnapshot.data();

        // Verifique se há dados e se há um campo 'role'
        if (userData && userData.role) {
          const userRole = userData.role;
          const userName = userData.name; 
          //console.log('User signed in successfully! User Role:', userRole);
          //localStorage.setItem('role', userRole);
          return { userRole, uid, email, userName };
        } else {
          console.error("Dados do usuário ou função não encontrados.");
        }
      }
      // O usuário está autenticado e seu e-mail foi verificado
    } else {
      console.error(
        "E-mail não verificado. Verifique seu e-mail antes de fazer login."
      );
      // Adicione lógica para lidar com usuários cujos e-mails não foram verificados
    }
  } catch (error: any) {
    console.error("Erro ao fazer login: ", error.message);
  }
};
