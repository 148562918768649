import './UploadPhoto.scss'
import React, { useEffect } from 'react';
import HeaderComponent from '../../../components/HeaderComponent/HeaderComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import StorageUserImageComponent from '../../../components/StorageUserImageComponent/StorageUserImageComponent';
import { auth } from '../../../databases/firebaseConfig';

const UploadPhoto: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const uid = state?.uid || localStorage.getItem('code');
  const nome = state?.userName || '';
  const logoUrl = state?.logoUrl || '';

    // Check if the user is already signed in
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (!user) {
          navigate("/");
        }
      });
  
      // Cleanup the subscription when the component unmounts
      return () => unsubscribe();
    }, []);
  
  return (
    <div className='student-page' style={{color: '#000'}}>
      <div>
      <HeaderComponent urlPhoto={uid} isFirstPage={false} nome={nome} logoUrl={logoUrl} />
      <div>
        <StorageUserImageComponent uid={uid}/>
      </div>
    </div>
    </div>
  );
};

export default UploadPhoto;
